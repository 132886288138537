import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../layouts/layout"
import SEO from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="404: Not Found" />
      <section className="uni-container about-hero flex-vertical">
        <div className="error-page">
          <h1 className="uni-h1">404: Not Found</h1>
          <p className="uni-hero-p">Sorry, we couldn't find the page you are looking for.</p>
          <Link to="/" className="uni-cta-btn btn">Go to Home Page</Link>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
